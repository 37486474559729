import React, { Component } from 'react'
import { navigate } from 'gatsby'

class RedirectJob extends Component {
  componentWillMount() {
    if (typeof window !== 'object') return null
    navigate('/jobs')
  }
  render() {
    return null
  }
}


export default RedirectJob
